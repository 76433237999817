import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import HeaderComponent from "../components/common/header";
import Footer from "../components/common/footer";
import { Card, Image, Modal } from "semantic-ui-react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import "../styles/seriesDetail.css";

export const query = graphql`
query SeriesDetailQuery($slug: String!) {
    contentfulSeries(slug: { eq: $slug }) {
      seriesTitle
      seriesIntro
      seriesImage {
        fluid(maxWidth: 4000) {
          src
        }
      }
      gallery {
        id
        fluid(maxWidth: 1200) {
          src
        }
      }
      productsRelated {
        name
        slug
        modelNumber
        coverImage {
          fluid(maxWidth: 400) {
            src
          }
        }
      }
      moreDetails {
        json  
      }
      catalog {
        file {
          url
        }
      }
    }
  }
`;

const SeriesDetailTemplate = ({ data }) => {
    const { contentfulSeries } = data;
    const [open, setOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const handleOpenImage = (index) => {
        setSelectedImageIndex(index);
        setOpen(true);
    };

    const handlePrev = () => {
        if (!contentfulSeries.gallery || contentfulSeries.gallery.length === 0) return;
        const newIndex = selectedImageIndex - 1 >= 0 ? selectedImageIndex - 1 : contentfulSeries.gallery.length - 1;
        setSelectedImageIndex(newIndex);
    };

    const handleNext = () => {
        if (!contentfulSeries.gallery || contentfulSeries.gallery.length === 0) return;
        const newIndex = selectedImageIndex + 1 < contentfulSeries.gallery.length ? selectedImageIndex + 1 : 0;
        setSelectedImageIndex(newIndex);
    };

    if (!contentfulSeries) {
        return <p>Loading...</p>;
    }

    const moreDetailsComponents = contentfulSeries.moreDetails
        ? documentToReactComponents(contentfulSeries.moreDetails.json)
        : null;

    return (
        <>
            <Helmet>
                <title>{contentfulSeries.seriesTitle} - DP Tile</title>
                <meta name="description" content={contentfulSeries.seriesIntro} />
            </Helmet>

            <HeaderComponent page="product" />
            <div className="series-detail-page">

                <div className="images-container">
                    {contentfulSeries.seriesImage && contentfulSeries.seriesImage.map((img, index) => (
                        <div key={index} className="image-item">
                            <img src={img.fluid.src} alt={`Series Image ${index}`} />
                        </div>
                    ))}
                </div>
                <h1>{contentfulSeries.seriesTitle}</h1>
                <h2>{contentfulSeries.seriesIntro}</h2>

                {contentfulSeries.productsRelated && (
                    <div className="products">
                        {contentfulSeries.productsRelated.map((product, index) => (
                            <Link to={`/products/${product.slug}`} key={index}>
                                <Card>
                                    {product.coverImage && (
                                        <Image src={product.coverImage.fluid.src} wrapped ui={false} />
                                    )}
                                    <Card.Content>
                                        <Card.Header>{product.name}</Card.Header>
                                        <Card.Meta>
                                            <span>{product.modelNumber}</span>
                                        </Card.Meta>
                                    </Card.Content>
                                </Card>
                            </Link>
                        ))}
                    </div>
                )}

                {moreDetailsComponents && (
                    <div className="rich-text-content">
                        {moreDetailsComponents}
                    </div>
                )}

                <p className="inspiration">Find Inspiration from Gallery</p>
                <div className="gallery">
                    {contentfulSeries.gallery && contentfulSeries.gallery.map((image, index) => (
                        <img key={index} src={image.fluid.src} alt={`Gallery Image ${index}`} onClick={() => handleOpenImage(index)} />
                    ))}
                    <Modal open={open} onClose={() => setOpen(false)} className="full-screen-modal" closeIcon>
                        <Modal.Content>
                            <button onClick={handlePrev} className="gallery-control left">❮</button>
                            {contentfulSeries.gallery && contentfulSeries.gallery.length > 0 &&
                                <Image src={contentfulSeries.gallery[selectedImageIndex].fluid.src} alt="Enlarged view" />}
                            <button onClick={handleNext} className="gallery-control right">❯</button>
                        </Modal.Content>
                    </Modal>
                </div>

                {contentfulSeries.catalog && contentfulSeries.catalog.file && (
                    <>
                        <div className="pdf-embed-container">
                            <embed
                                src={contentfulSeries.catalog.file.url}
                                type="application/pdf"
                                width="100%"
                                height="600px"
                            />
                        </div>
                        <a
                            href={contentfulSeries.catalog.file.url}
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                            className="download-button"
                        >
                            Download Catalog
                        </a>
                    </>
                )}
                <Footer />
            </div>
        </>
    );
};

export default SeriesDetailTemplate;
